import React from 'react';

function Hero({ bgImage }){

    return (

        <>
            <div className="header-hero bg_cover d-lg-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="header-hero-content">
                                <h1 className="hero-title">THE FUTURE OF<br/><b>LINGUISTIC QA</b></h1>
                                <h2 className="hero-subtitle mt-3 text-center text-lg-left">
                                    <span>API-BASED</span> <span>LOCALE-SPECIFIC</span> <span>QA AS YOU TYPE</span> <span>ZERO LEARNING CURVE</span> <span>FULLY CUSTOMISABLE</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-hero-image d-flex align-items-center">
                    <div className="image">
                        <img src={bgImage} alt="Hero" />
                    </div>
                </div>
            </div>
            <div class="features container">
                <div className="row">
                    <div class="feature col-lg-6  mb-30">
                        <h2 className="title mb-30 text-center title-brand-color">QA as you type</h2> 
                        <p className="text text-center">You don’t have to wait for a report after you submit your translation. A highly responsive Live QA function triggers locale-specific checks while you type!</p>
                    </div>
                    <div class="feature col-lg-6  mb-30 ">
                        <h2 className="title mb-30 text-center title-brand-color-alt">API-based</h2> 
                        <p className="text text-center">Our solution has been designed with modularity in mind. The power of APIs eliminates the need for file exchange and makes every customisation possible.</p>
                    </div>
                </div>
            </div>
            <div class="features container">
                <div className="row">
                    <div class="feature col-lg-6  mb-30 ">
                        <h2 className="title mb-30 text-center title-brand-color">Locale-specific</h2> 
                        <p className="text text-center">Not just generic QA checks any more. lexiQA applies pre-configured grammar and localization rules for each individual locale.</p>
                    </div>
                    <div class="feature col-lg-6  mb-30 ">
                        <h2 className="title mb-30 text-center title-brand-color-alt">Fully customisable</h2> 
                        <p className="text text-center">Ensure compliance with your style guides for any locale. All our checks can be tailored to reflect your requirements at scale.</p>
                    </div>
                </div>
            </div>
                
        </>

    );

}

export default Hero;