import React from "react"
import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
// import { Link } from "gatsby"
// import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, children }) => {

  /* eslint no-undef: 0 */
  const rootPath = `${__PATH_PREFIX__}/`

  if (location.pathname === rootPath) {
    // In case we are in /
  } else {
    // All other cases, e.g. /blog/
  }

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`
        // border: '2px solid red',
        // maxWidth: rhythm(24),
        // padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <Header component={<Hero/>} title={title} />
      {/* <header>{header}</header> */}
      <main>
        { children }
      </main>
      <Footer/>
      {/* 
      <footer>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </footer> 
      */}

    </div>
  )
}

export default Layout
